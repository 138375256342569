import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Row = styled.div`
  width: 100%;
  padding: 8px 8px 7px 8px;
  display: flex;
  border-bottom-color: #d8d8d8;
  border-bottom-width: 1px;
  border-bottom-style: solid;
`

const Title = styled.p`
  width: 80px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
  margin-right: 16px;
`

const Description = styled.p`
  max-width: calc(100% - 80px - 16px);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
`

const CompanyProfile = props => {
  return (
    <Row>
      <Title>{props.title}</Title>
      <Description>{props.description}</Description>
    </Row>
  )
}

CompanyProfile.prototype = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

export default CompanyProfile
