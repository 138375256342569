import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import OfficerProfileImage from "../officer-profile-image"

const Root = styled.div`
  display: flex;
  justify-content: center;
  width: 504px;
  @media screen and (max-width: 1079px) {
    width: 680px;
  }
  @media screen and (max-width: 799px) {
    width: 100%;
  }
`

const Picture = styled.div`
  height: 152px;
  width: 152px;
  border-radius: 130.5px;
  /* background-color: #e8d7a4; */
  margin-right: 24px;
  /* box-shadow: 0 0 50px 0 rgba(0,0,0,0.15); */
  @media screen and (max-width: 799px) {
    height: 80px;
    width: 80px;
    border-radius: 130.5px;
    margin-right: 16px;
    // z-indexを指定しないとborder-radiusが効かない?
    z-index: 1;
  }
`

const RedBackgroundedPicture = styled(Picture)`
  background-color: #e8d7a4;
`

const BlueBackgroundedPicture = styled(Picture)`
  background-color: #a4c4e8;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  /* max-width: calc(100% - 152px - 24px); */
  max-width: calc(100% - 152px - 24px);
  h1 {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 8px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 799px) {
    max-width: calc(100% - 80px - 16px);
  }
`

// const Title = styled.h1`
//   font-size: 18px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 28px;
//   margin-bottom: 0;
// `

// const Description = styled.p`
//   font-size: 14px;
//   letter-spacing: 0;
//   line-height: 28px;
//   margin-bottom: 0;
// `

const OfficerProfile = props => {
  const photo = props.photo
  const descriptionHTML = props.description.childMarkdownRemark.html
  const isEven = props.isEven
  return (
    <Root>
      {!isEven && (
        <RedBackgroundedPicture>
          <OfficerProfileImage image={photo} />
        </RedBackgroundedPicture>
      )}
      {isEven && (
        <BlueBackgroundedPicture>
          <OfficerProfileImage image={photo} />
        </BlueBackgroundedPicture>
      )}
      <Container dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
    </Root>
  )
}

OfficerProfile.propTypes = {
  photo: PropTypes.any.isRequired,
  description: PropTypes.any.isRequired,
}

export default OfficerProfile
