import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"

const Root = styled.div`
  /* padding: 35px 0 35px 0; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%; 
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  h1 {
    font-size: 24px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 36px;
    margin-bottom: 20px;
  }
  p {
    font-size: 16px;
    font-weight: normal;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 0;
  }
  strong {
    font-weight: bold;
    color: #EF3C66;
  }
  @media screen and (max-width: 799px) {
    min-width: 100%;
  }
`

const Paragraph = props => {
  const paragraphHTML = props.paragraphHTML
  return (
    <Root className="paragraph">
      <Container dangerouslySetInnerHTML={{ __html: paragraphHTML }} />
    </Root>
  )
}

Paragraph.propTypes = {
  paragraphHTML: PropTypes.string.isRequired,
}

export default Paragraph
