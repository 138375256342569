import React from "react"
import styled from "@emotion/styled"
import OfficerProfile from "./officer-profile"

const Root = styled.div`
  // 親要素のpaddingを無視して画面いっぱいに表示
  margin: 0px -1.0875rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 50px 24px 50px 24px;
  background-color: #f9f9f9;
  @media screen and (max-width: 799px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`

const Container = styled.div`
  max-width: 1032px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  // gap使わないと実装面倒
  column-gap: 24px;
  row-gap: 40px;
  @media screen and (max-width: 1079px) {
    justify-content: center;
    // gapが効かない可能性のあるモバイル端末ではmarginで対応
    gap: unset;
    .profile {
      margin-bottom: 40px;
    }
    .profile:last-child {
      margin-bottom: 0;
    }
  }
  @media screen and (max-width: 799px) {
    flex-direction: column;
  }
`

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-bottom: 40px;
`

const OfficerProfiles = officers => {
  const unsorted = officers.officers
  const sorted = unsorted.sort((element1, element2) => {
    const diff = element1.displayOrder >= element2.displayOrder
    if (diff) {
      return 1
    } else {
      return -1
    }
  })

  const profiles = sorted.map(
    (officer, index) => {
      const photo = officer.photo
      const description = officer.description
      const key = officer.contentful_id
      const isEven = (index + 1) % 2 === 0
      return (
        <div className="profile" key={key}>
          <OfficerProfile photo={photo} description={description} isEven={isEven}/>
        </div>
      )
    }
  )

  return (
    <Root>
      <Title>役員プロフィール</Title>
      <Container>{profiles}</Container>
    </Root>
  )
}

export default OfficerProfiles
