import React from "react"
import styled from "@emotion/styled"
import Paragraph from "./paragraph"

const Root = styled.div`
  /* padding: 35px 0 35px 0; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: 40px;
  .paragraph {
    margin-bottom: 40px;
  }
  .paragraph:last-child {
    margin-bottom: 0;
  }
`

const Paragraphs = paragraphs => {
  const unsorted = paragraphs.paragraphs
  const sorted = unsorted.sort((element1, element2) => {
    const diff = element1.displayOrder >= element2.displayOrder
    if (diff) {
      return 1
    } else {
      return -1
    }
  })
  return (
    <Root>
      {sorted.map(paragraph => {
        const paragraphHTML =
          paragraph.childContentfulAboutUsParagraphParagraphTextNode
            .childMarkdownRemark.html
        const key = paragraph.contentful_id
        return <Paragraph paragraphHTML={paragraphHTML} key={key} />
      })}
    </Root>
  )
}

export default Paragraphs
