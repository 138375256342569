import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import DynamicImage from "../dynamic-image"

const Root = styled.div`
  padding: 40px 0 35px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Title = styled.h1`
  width: 100%;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 45px;
  margin-bottom: 41px;
`

const MissionHeader = image => {
  return (
    <Root>
      <Container>
        <Title>会社情報</Title>
        <DynamicImage image={image.image.gatsbyImageData} />
      </Container>
    </Root>
  )
}

MissionHeader.prototype = {
  image: PropTypes.any.isRequired,
}

export default MissionHeader
