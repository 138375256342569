import React from "react"
import styled from "@emotion/styled"
import CompanyHistory from "./company-history"

const Root = styled.div`
  padding: 40px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  width: 100%;
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Rows = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`

const Title = styled.h1`
  width: 100%;
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 20px;
`

const CompanyHistories = histories => {
  const unsorted = histories.histories
  const sorted = unsorted.sort((element1, element2) => {
    const diff =
      element1.year * 100 + element1.month >=
      element2.year * 100 + element2.month
    if (diff) {
      return 1
    } else {
      return -1
    }
  })
  return (
    <Root>
      <Container>
        <Title>沿革</Title>
        <Rows>
          {sorted.map(history => {
            const date = `${history.year}年${history.month}月`
            const description = history.description
            const key = history.contentful_id
            return (
              <CompanyHistory date={date} description={description} key={key} />
            )
          })}
        </Rows>
      </Container>
    </Root>
  )
}

export default CompanyHistories
