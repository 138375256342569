import React from "react"
import styled from "@emotion/styled"
import CompanyProfile from "./company-profile"
import CompanyMap from "./company-map"
import DynamicImage from "../dynamic-image"
import { Link } from "gatsby";


const Root = styled.div`
  padding: 50px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  @media screen and (max-width: 799px) {
    max-width: 100%;
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-bottom: 36px;
`

const Iso = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  .iso {
    margin-bottom: 16px;
  }
  .iso:last-child {
    margin-bottom: 0;
  }
`

const Title = styled.h1`
  width: 100%;
  text-align: start;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 30px;
  margin-bottom: 20px;
`

const Profiles = styled.div`
  margin-bottom: 30px;
`

const LinkedImage = styled(Link)`
`

const SmallIsoSection = props => {
  const unsorted = props.qualifications
  return GetQualificationImage(unsorted, false)
}

const LargeIsoSection = props => {
  const unsorted = props.largeQualifications

  return GetQualificationImage(unsorted, true)
}

const GetQualificationImage = (unsorted, isLarge) => {
  const Images = styled.div`
    display: flex;
    .image {
      margin: 0 16px 16px 0;
    }
    .image:last-child {
      margin-right: 0;
    }
  `

  const Image = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 132px;
  `

  const LargeImage = styled(Image)`
    width: 320px;
  `

  const sorted = unsorted.sort((element1, element2) => {
    const diff = element1.displayOrder !== element2.displayOrder
    if (diff) {
      return 1
    } else {
      return -1
    }
  })
  const images = sorted.map(element => {
    let urlLink = element.title
    if(urlLink === "ISO 9001"){
      urlLink = "/iso9001"
    }else if(urlLink === "ISO27001"){
      urlLink = "/iso27001"
    }else if(urlLink !== "ISO 9001" && urlLink !== "ISO27001"){
      urlLink = ""
    }
    const shouldRenderLinkedImage = urlLink !== ""

    return (
      <>
        {shouldRenderLinkedImage ? (
          <LinkedImage to={urlLink}>
            {isLarge ? (
              <LargeImage className="image" key={element.contentful_id}>
                <DynamicImage
                  image={element.image.gatsbyImageData}
                  objectFit="contain"
                />
              </LargeImage>
            ) : ( 
              <Image className="image" key={element.contentful_id}>
              <DynamicImage
                image={element.image.gatsbyImageData}
                objectFit="contain"
              />
              </Image>
            )}
          </LinkedImage>
        ) : (
          isLarge ? (
            <LargeImage className="image" key={element.contentful_id}>
              <DynamicImage
                image={element.image.gatsbyImageData}
                objectFit="contain"
              />
            </LargeImage>
          ) : (
            <Image className="image" key={element.contentful_id}>
              <DynamicImage
                image={element.image.gatsbyImageData}
                objectFit="contain"
              />
            </Image>
          )
        )}
      </>
    )
  })

  return <Images>{images}</Images>
}

const CompanyProfiles = props => {
  const unsorted = props.profiles
  const sorted = unsorted.sort((element1, element2) => {
    const diff = element1.displayOrder !== element2.displayOrder
    if (diff) {
      return 1
    } else {
      return -1
    }
  })
  const qualifications = props.qualifications
  const largeQualifications = props.largeQualifications
  return (
    <Root>
      <Container>
        <Title>会社概要</Title>
        <Wrapper>
          <Profiles>
            {sorted.map(profile => {
              const title = profile.title
              const description = profile.description
              const key = profile.contentful_id
              return (
                <CompanyProfile
                  title={title}
                  description={description}
                  key={key}
                />
              )
            })}
          </Profiles>
          <Iso>
            <SmallIsoSection className="iso" qualifications={qualifications} />
            <LargeIsoSection
              className="iso"
              largeQualifications={largeQualifications}
            />
          </Iso>
        </Wrapper>
        <CompanyMap />
      </Container>
    </Root>
  )
}

export default CompanyProfiles
