import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/seo"
import MissionHeader from "../components/aboutus/mission-header.js"
import PresidentProfile from "../components/aboutus/president-profile"
import OfficerProfiles from "../components/aboutus/officer-profiles"
import CompanyHistories from "../components/aboutus/company-histories"
import CompanyProfiles from "../components/aboutus/company-profles"
import Paragraphs from "../components/aboutus/paragraphs"
import { UsePageContext } from "../context/page-context"
import { useState } from "react"

const AboutUs = ({ data }) => {
  const { setIsAtContactPage, setIsAtNotfoundPage } = UsePageContext()
  useState(() => {
    setIsAtContactPage(false)
    setIsAtNotfoundPage(false)
  })

  const aboutUsData = data.allContentfulAboutUs.edges[0].node
  return (
    <>
      <Seo title="会社情報" description="株式会社アクアの会社説明です。" />
      <MissionHeader image={aboutUsData.topImage} />
      <Paragraphs paragraphs={aboutUsData.paragraphs} />
      <PresidentProfile
        photo={aboutUsData.presidentImage}
        profile={aboutUsData.presidentProfile}
      />
      <OfficerProfiles officers={aboutUsData.officerProfiles} />
      <CompanyHistories histories={aboutUsData.companyHistories} />
      <CompanyProfiles
        profiles={aboutUsData.companyProfiles}
        qualifications={aboutUsData.companyQualifications}
        largeQualifications={aboutUsData.largeCompanyQualifications}
      />
    </>
  )
}

export const query = graphql`
  query AboutUsQuery {
    allContentfulAboutUs {
      edges {
        node {
          topImage {
            gatsbyImageData
            description
          }
          paragraphs {
            childContentfulAboutUsParagraphParagraphTextNode {
              childMarkdownRemark {
                html
              }
            }
            displayOrder
            contentful_id
          }
          childContentfulAboutUsPresidentProfileTextNode {
            presidentProfile
          }
          presidentImage {
            gatsbyImageData
            description
          }
          presidentProfile {
            childMarkdownRemark {
              html
            }
          }
          officerProfiles {
            description {
              childMarkdownRemark {
                html
              }
            }
            name
            displayOrder
            photo {
              gatsbyImageData
              description
            }
            contentful_id
          }
          companyHistories {
            year
            month
            description
            contentful_id
          }
          companyProfiles {
            title
            description
            displayOrder
            contentful_id
          }
          companyQualifications {
            title
            image {
              gatsbyImageData
            }
            displayOrder
            contentful_id
          }
          largeCompanyQualifications {
            title
            image {
              gatsbyImageData
            }
            displayOrder
            contentful_id
          }
        }
      }
    }
  }
`

export default AboutUs
