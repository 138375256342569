import React from "react"
import styled from "@emotion/styled"

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`

const Map = styled.iframe`
  width: 100%;
  height: 320px;
  border: none;
  margin-bottom: 0;
`

const Description = styled.p`
  max-width: 100%;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-bottom: 0;
`

const CompanyMap = () => {
  return (
    <Root>
      <Map
        loading="lazy"
        allowfullscreen
        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_awcl3iLGGAR4wUTAd7hOiE&key=AIzaSyB6oiMpifkbLQaViViMQTvCc0Z5z3XgES0"
      ></Map>
      <Container>
        <Description>
          ＪＲ中央線・総武線（麹町口）「四ツ谷」駅　　徒歩2分
        </Description>
        <Description>東京メトロ丸ノ内線・南北線「四ツ谷」駅　徒歩3分</Description>
        <Description>東京メトロ有楽町線「麴町」駅（出口2）　徒歩6分</Description>
      </Container>
    </Root>
  )
}

export default CompanyMap
