import React from "react"
import styled from "@emotion/styled"
import PropTypes from "prop-types"
import DynamicImage from "../dynamic-image"

const Root = styled.div`
  /* padding: 40px 0 35px 0; */
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  margin-bottom: 50px;
`

const Container = styled.div`
  max-width: 680px;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
`

const Profile = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin-top: 20px;
  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 30px;
    margin-bottom: 12px;
  }
  p {
    font-weight: normal;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 0;
  }
`

// const Title = styled.h1`
//   font-size: 20px;
//   font-weight: bold;
//   letter-spacing: 0;
//   line-height: 30px;
//   margin-bottom: 0;
// `

// const Description = styled.p`
//   font-size: 14px;
//   letter-spacing: 0;
//   line-height: 28px;
//   margin-bottom: 0;
// `

const PresidentProfile = props => {
  const photo = props.photo
  const profileHTML = props.profile.childMarkdownRemark.html
  return (
    <Root>
      <Container>
        <DynamicImage image={photo.gatsbyImageData} />
        <Profile dangerouslySetInnerHTML={{ __html: profileHTML }} />
      </Container>
    </Root>
  )
}

PresidentProfile.propTypes = {
  photo: PropTypes.any.isRequired,
}

export default PresidentProfile
